import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { Layout, Button, Modal as AntdModal } from "antd";
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  CloseOutlined,
  UnlockOutlined,
  LoginOutlined,
} from "@ant-design/icons";
import Logo from "./Logo";
import NavPanel from "./NavPanel";
import NavSearch from "./NavSearch";
import { toggleCollapsedNav, onMobileNavToggle } from "redux/actions/Theme";
// import { NavSupport } from "./NavSupport";
import {
  NAV_TYPE_TOP,
  SIDE_NAV_COLLAPSED_WIDTH,
  SIDE_NAV_WIDTH,
} from "constants/ThemeConstant";
import utils from "utils";

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { PaymentElement } from "@stripe/react-stripe-js";

import { NavProfile } from "./NavProfile";
import { NavNotification } from "./NavNotification";
import PaymentForm from "components/PaymentForm";
import Modal from "UI/Modal/Modal";
import useAuth from "hooks/useAuth";

const { Header } = Layout;

const stripePromise = loadStripe(
  "pk_live_51MAb5IBaB8F7rFU4dZPTLUUVkxRGqxVwbARSnu0qOmiu27b6oDWdV253YfpDLtisfxOz9bBGHoaJ6VcdEIlplnaZ00S6niuk3w"
);
// const stripePromise = loadStripe(
//   "pk_test_51MAb5IBaB8F7rFU4x0bjHUq3iwv4gME3bY5y30CW7Iv2kXNYqVFxXC28H1LQU2C48EPKF1uyiEYhCJeCfZlmgCMY00XylU6Zmc"
// );

export const HeaderNav = (props) => {
  const {
    navCollapsed,
    mobileNav,
    navType,
    headerNavColor,
    toggleCollapsedNav,
    onMobileNavToggle,
    isMobile,
    currentTheme,
    direction,
    openLoginPopup,
  } = props;
  const [searchActive, setSearchActive] = useState(false);
  const [isOpenPaymentModal, setIsOpenPaymentModal] = useState(false);
  const [testState, setTestState] = useState();
  const { auth, setAuth } = useAuth();

  const options = {
    // passing the client secret obtained from the server
    clientSecret:
      "sk_test_51Lwe0hKCfU2wkOg1IIv1pKaeHh3M2DHpM9Q7dHd6hU2QOJC6xix28irywTDtiNXXEHmyBgZdbRscZig9XQhWPH3I006RTsZBrL",
  };

  const onSearchClose = () => {
    setSearchActive(false);
  };

  const handleOpen = () => {
    setIsOpenPaymentModal(true);
  };

  const handleCancel = () => {
    setIsOpenPaymentModal(false);
  };

  const onToggle = () => {
    if (!isMobile) {
      toggleCollapsedNav(!navCollapsed);
    } else {
      onMobileNavToggle(!mobileNav);
    }
  };

  const isNavTop = navType === NAV_TYPE_TOP ? true : false;
  const mode = () => {
    if (!headerNavColor) {
      return utils.getColorContrast(
        currentTheme === "dark" ? "#00000" : "#ffffff"
      );
    }
    return utils.getColorContrast(headerNavColor);
  };
  const navMode = mode();
  const getNavWidth = () => {
    if (isNavTop || isMobile) {
      return "0px";
    }
    if (navCollapsed) {
      return `${SIDE_NAV_COLLAPSED_WIDTH}px`;
    } else {
      return `${SIDE_NAV_WIDTH}px`;
    }
  };

  useEffect(() => {
    if (!isMobile) {
      onSearchClose();
    }
  });

  return (
    <>
      <Header
        className={`app-header ${navMode}`}
        style={{ backgroundColor: headerNavColor }}
      >
        <div
          className={`app-header-wrapper ${isNavTop ? "layout-top-nav" : ""}`}
        >
          <Logo logoType={navMode} />
          <div
            className="nav"
            style={{ width: `calc(100% - ${getNavWidth()})` }}
          >
            <div className="nav-left">
              <ul className="ant-menu ant-menu-root ant-menu-horizontal">
                {isNavTop && !isMobile ? null : (
                  <li
                    className="ant-menu-item ant-menu-item-only-child"
                    onClick={() => {
                      onToggle();
                    }}
                  >
                    {navCollapsed || isMobile ? (
                      <MenuUnfoldOutlined className="nav-icon" />
                    ) : (
                      <MenuFoldOutlined className="nav-icon" />
                    )}
                  </li>
                )}
              </ul>
            </div>
            {auth && auth.accessToken ? (
              <div className="nav-right">
                {auth && !auth?.isPaid ? (
                  <>
                    <div className="btn_wrapper">
                      {/* <Button
                        type="primary"
                        onClick={() => {
                          setAuth({
                            email: "abc@a.com",
                            accessToken:
                              "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6ImFiY0BhYmMuY29tIiwiaXNQYWlkIjpmYWxzZSwiaWF0IjoxNjY3MzY4MzMwLCJleHAiOjE2Njc5NzMxMzB9.BOAQT7KrrlRJxjbKUXfi6FyL8Oql-m_zVPu0VZ1gEnM",
                            isPaid: true,
                          });
                          setTestState("updated");
                        }}
                      >
                        Update Auth
                      </Button> */}
                      <Button
                        type="primary"
                        shape="round"
                        onClick={handleOpen}
                        // style={{ backgroundColor: "#34a900" }}
                      >
                        <UnlockOutlined /> Unlock
                      </Button>
                    </div>
                  </>
                ) : null}
                {/* <NavSupport /> */}
                <NavNotification />
                <NavProfile />
                {/* <NavPanel direction={direction} /> */}
              </div>
            ) : null}
            {!auth.email ? (
              <>
                <div className="nav-right">
                  <div className="btn_wrapper">
                    <Button
                      type="primary"
                      shape="round"
                      onClick={openLoginPopup}
                    >
                      Sign In <LoginOutlined />
                    </Button>
                  </div>
                </div>
              </>
            ) : null}
            <NavSearch active={searchActive} close={onSearchClose} />
          </div>
        </div>
      </Header>
      {isOpenPaymentModal ? (
        <Modal
          title="Basic Modal"
          // onOk={handleOk}
          // onCancel={handleCancel}
          onClose={() => handleCancel()}
        >
          <div className="modalHeader">
            <div className="modalTitle">
              <p>Upgrade</p>
            </div>
            <div className="modalCloseButton" onClick={handleCancel}>
              <CloseOutlined />
            </div>
          </div>
          <div className="modalBody">
            <Elements stripe={stripePromise}>
              <PaymentForm setIsOpenPaymentModal={setIsOpenPaymentModal} />
            </Elements>
          </div>

          <div className="modalFooter">
            <div className="buttonGroup">
              <Button onClick={handleCancel}>Cancel</Button>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
};

const mapStateToProps = ({ theme }) => {
  const {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  } = theme;
  return {
    navCollapsed,
    navType,
    headerNavColor,
    mobileNav,
    currentTheme,
    direction,
  };
};

export default connect(mapStateToProps, {
  toggleCollapsedNav,
  onMobileNavToggle,
})(HeaderNav);
