import React, { useState, useEffect } from "react";
import axios from "axios";
import { Menu, Dropdown, Badge, Avatar, List, Button } from "antd";
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
} from "@ant-design/icons";

import Flex from "components/shared-components/Flex";
import { BASE_URL } from "../../constants";
import useAuth from "../../hooks/useAuth";
import { useHistory } from "react-router-dom";

const getIcon = (icon) => {
  switch (icon) {
    case "mail":
      return <MailOutlined />;
    case "alert":
      return <WarningOutlined />;
    case "check":
      return <CheckCircleOutlined />;
    default:
      return <MailOutlined />;
  }
};

export const NavNotification = () => {
  const { auth } = useAuth();
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const navigate = useHistory();

  const requestOptions = {
    headers: {
      Authorization: `Bearer ${auth.accessToken}`,
    },
  };

  useEffect(() => {
    if (auth.email) {
      axios(
        `${BASE_URL}/users/notifications?email=${auth.email}`,
        requestOptions
      )
        .then((res) => {
          //console.log(auth.accessToken);
          setData(res.data);
        })
        .catch((e) => console.log(e));
    }
  }, [auth.email]);

  const deleteNotifications = async () => {
    try {
      await axios.get(
        `${BASE_URL}/users/delete-notifications?email=${auth.email}`,
        requestOptions
      );
      setData([]);
    } catch (e) {
      console.log(e);
    }
  };

  const getNotificationBody = (list) => {
    return list.length > 0 ? (
      <List
        size="small"
        itemLayout="horizontal"
        dataSource={data}
        renderItem={(item) => (
          <List.Item
            onClick={() => {
              navigate.push("/app/watch-list");
              setVisible(false);
            }}
            className="list-clickable"
          >
            <Flex alignItems="center">
              {/* <div className="pr-3">
            {item.img? <Avatar src={`/img/avatars/${item.img}`} /> : <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />}
          </div> */}
              <div className="mr-3">
                <span className="font-weight-bold text-dark">
                  {`${item.newHomes} new homes `}{" "}
                </span>
                <span className="text-gray-light">{`in ${item.location}`}</span>
              </div>
              {/* <small className="ml-auto">{item.time}</small> */}
            </Flex>
          </List.Item>
        )}
      />
    ) : (
      <div className="empty-notification">
        <img
          src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
          alt="empty"
        />
        <p className="mt-3">You have viewed all notifications</p>
      </div>
    );
  };

  const handleVisibleChange = (flag) => {
    setVisible(flag);
  };

  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notifications</h4>
        <Button
          className="text-primary"
          type="link"
          onClick={() => deleteNotifications()}
          size="small"
        >
          Clear
        </Button>
      </div>
      <div className="nav-notification-body">{getNotificationBody(data)}</div>
      {data.length > 0 ? (
        <div className="nav-notification-footer">
          {/* <a className="d-block" href="#/">
            View all
          </a> */}
        </div>
      ) : null}
    </div>
  );

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={["click"]}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data.length}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
