import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { LockOutlined, MailOutlined } from "@ant-design/icons";
import { Button, Form, Input, Divider, Alert, message } from "antd";
import {
  showAuthMessage,
  showLoading,
  hideAuthMessage,
  authenticated,
} from "redux/actions/Auth";
// import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import JwtAuthService from "services/JwtAuthService";
import axios from "axios";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import { BASE_URL } from "../../../constants";
const rules = {
  email: [
    {
      required: true,
      message: "Please input your email address",
    },
    {
      type: "email",
      message: "Please enter a valid email!",
    },
  ],
  password: [
    {
      required: true,
      message: "Please input your password",
    },
  ],
  confirm: [
    {
      required: true,
      message: "Please confirm your password!",
    },
    ({ getFieldValue }) => ({
      validator(rule, value) {
        if (!value || getFieldValue("password") === value) {
          return Promise.resolve();
        }
        return Promise.reject("Passwords do not match!");
      },
    }),
  ],
};

export const RegisterForm = (props) => {
  const signupError = () => {
    message.error("Error", 5);
  };

  const notSamePassword = () => {
    message.error("Passwords do not match!", 5);
  };

  const signupSuccess = () => {
    message.success("Success", 5);
  };

  const {
    signInemail,
    showLoading,
    token,
    loading,
    redirect,
    message1,
    showMessage,
    hideAuthMessage,
    authenticated,
    allowRedirect,
    isSignUpSuccessfull,
    setIsSignupSuccessfull,
    handleOpenSignInScreen,
    responseGoogle,
  } = props;
  const [form] = Form.useForm();
  // let history = useHistory();

  const [email, setEmail] = useState("" || signInemail);
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [signupLoading, setSignupLoading] = useState(false);
  const [signUpSuccessMessage, setSignUpSuccessMessage] = useState();

  // const client_id =
  //   "360795337142-mf91endq8un259dpmm4tiv3f0v4i7ahf.apps.googleusercontent.com";
  const client_id =
    "22314450281-hb9bqbc65tskvgb8b2jef1eumthhnmps.apps.googleusercontent.com";
  //   console.log(email, password, confirmPassword);

  const onSignUp = async () => {
    setSignupLoading(true);
    // form
    //   .validateFields()
    //   .then((values) => {
    // showLoading();
    if (password === confirmPassword) {
      await axios
        .post(`${BASE_URL}/auth/signup`, {
          email: email.toLowerCase(),
          password: password,
        })
        .then((response) => {
          console.log("signup response", response);
          if (response.data.success === true) {
            signupSuccess();
            setIsSignupSuccessfull(true);
            setEmail("");
            setPassword("");
            setConfirmPassword("");
            setSignupLoading(false);
            setSignUpSuccessMessage(response?.data?.msg);
          } else {
            signupError();
            setIsSignupSuccessfull(false);
            setSignupLoading(false);
          }
        });
    } else {
      notSamePassword();
      setSignupLoading(false);
    }
    // const fakeToken = "fakeToken";
    // JwtAuthService.signUp(values)
    //   .then((resp) => {
    //     authenticated(fakeToken);
    //   })
    //   .then((e) => {
    //     showAuthMessage(e);
    //   });
    // })
    // .catch((info) => {
    //   console.log("Validate Failed:", info);
    // });
  };

  // useEffect(() => {
  //   if (token !== null && allowRedirect) {
  //     // history.push(redirect);
  //   }
  //   if (showMessage) {
  //     setTimeout(() => {
  //       hideAuthMessage();
  //     }, 3000);
  //   }
  // });

  const renderOtherSignIn = (
    <div>
      <Divider>
        <span className="text-muted font-size-base font-weight-normal">
          or connect with
        </span>
      </Divider>
      <div className="google_btn_container">
        {/* <GoogleLogin
          clientId={client_id}
          // render={(renderProps) => (
          //   <i
          //     className="fa fa-google-plus"
          //     onClick={renderProps.onClick}
          //     disabled={renderProps.disabled}
          //   ></i>
          // )}
          buttonText="CONTINUE WITH GOOGLE"
          onSuccess={props.responseGoogle}
          onFailure={props.responseGoogle}
          cookiePolicy={"single_host_origin"}
          style={{ width: "100%" }}
        /> */}
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            // console.log(credentialResponse);
            responseGoogle(credentialResponse);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0,
        }}
      >
        <Alert type="error" showIcon></Alert>
      </motion.div>
      <Form
        form={form}
        layout="vertical"
        name="register-form"
        // onFinish={() => onSignUp()}
      >
        <Form.Item name="email" label="Email" rules={rules.email} hasFeedback>
          <Input
            defaultValue={signInemail}
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            prefix={<MailOutlined className="text-primary" />}
          />
        </Form.Item>
        <Form.Item
          //   name="password"
          label="Password"
          rules={rules.password}
          hasFeedback
        >
          <Input.Password
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            prefix={<LockOutlined className="text-primary" />}
          />
        </Form.Item>
        <Form.Item
          //   name="confirm"
          label="Confirm Password"
          rules={rules.confirm}
          hasFeedback
        >
          <Input.Password
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            prefix={<LockOutlined className="text-primary" />}
          />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            // htmlType="submit"
            disabled={isSignUpSuccessfull}
            block
            loading={signupLoading}
            onClick={() => onSignUp()}
          >
            Sign Up
          </Button>
        </Form.Item>
      </Form>
      {isSignUpSuccessfull && (
        <div className="signup_msg_sec">
          <p className="success_msg">
            {signUpSuccessMessage}{" "}
            <a onClick={handleOpenSignInScreen}>Go to Sign In</a>
          </p>
        </div>
      )}
      {renderOtherSignIn}
    </>
  );
};

const mapStateToProps = ({ auth }) => {
  const { loading, message1, showMessage, token, redirect } = auth;
  return { loading, message1, showMessage, token, redirect };
};

const mapDispatchToProps = {
  showAuthMessage,
  hideAuthMessage,
  showLoading,
  authenticated,
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
