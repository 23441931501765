import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Button, Form, Input, Divider, Alert, message } from "antd";
import { MailOutlined, LockOutlined } from "@ant-design/icons";
import PropTypes from "prop-types";
import { GoogleSVG, FacebookSVG } from "assets/svg/icon";
import CustomIcon from "components/util-components/CustomIcon";
import {
  showLoading,
  showAuthMessage,
  hideAuthMessage,
  authenticated,
} from "redux/actions/Auth";
import JwtAuthService from "services/JwtAuthService";
// import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import axios from "axios";
import { gapi } from "gapi-script";
import useAuth from "../../../hooks/useAuth";
import { useHistory } from "react-router-dom";
// import GoogleLogin from "react-google-login";
import { GoogleLogin } from "@react-oauth/google";
import { useDispatch, useSelector } from "react-redux";
import { BASE_URL } from "../../../constants";
import { localService } from "./../../../services/LocalStorageService";

import { resetMarketData } from "./../../../redux/actions/MarketData";

export const LoginForm = (props) => {
  //   let history = useHistory();
  const { setAuth, persist, setPersist, setLoginStat } = useAuth();
  const {
    email,
    setEmail,
    setShowSignupScreen,
    responseGoogle,
    otherSignIn,
    showForgetPassword,
    hideAuthMessage,
    onForgetPasswordClick,
    showLoading,
    extra,
    loading,
    showMessage,
    message1,
    authenticated,
    showAuthMessage,
    token,
    redirect,
    allowRedirect,
    setUserQuestions,
  } = props;
  const dispatch = useDispatch();
  // const client_id =
  //   "360795337142-mf91endq8un259dpmm4tiv3f0v4i7ahf.apps.googleusercontent.com";
  const client_id =
    "22314450281-hb9bqbc65tskvgb8b2jef1eumthhnmps.apps.googleusercontent.com";
  const [password, setPassword] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  const [isEmailVerified, setIsEmailVerified] = useState(false);
  const [verifyButtonLoading, setVerifyButtonLoading] = useState(false);
  const [verifyErrorMessage, setVerifyErrorMessage] = useState();

  const navigate = useHistory();

  const loginFail = () => {
    message.error("Wrong username or password.", 7);
  };

  // useEffect(() => {
  //   gapi.load("client:auth2", () => {
  //     gapi.auth2.init({ clientId: client_id });
  //   });
  // }, []);

  useEffect(() => {
    localStorage.setItem("persist", true);
  }, [persist]);

  const handleEmailInput = (value) => {
    setEmail(value);
    setVerifyErrorMessage("");
  };

  const verifyEmail = async () => {
    setVerifyButtonLoading(true);
    setVerifyErrorMessage("");
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/emailVerifyLink`,
        JSON.stringify({ email: email.toLowerCase() }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
          // credentials: "include",
        }
      );
      if (!res.data) {
        setVerifyButtonLoading(false);
        setShowSignupScreen(true);
        return;
      }
      if (!res?.data?.verified) {
        setIsEmailVerified(false);
        setVerifyButtonLoading(false);
        setVerifyErrorMessage(res?.data?.msg);
        return;
      }
      setIsEmailVerified(true);

      console.log("verified", res);
    } catch (e) {
      console.log(e);
      setVerifyButtonLoading(false);
      setShowSignupScreen(true);
    }
  };

  const onLogin = async (values) => {
    showLoading();
    setButtonLoading(true);
    try {
      const res = await axios.post(
        `${BASE_URL}/auth/login`,
        JSON.stringify({ email: email, password: password }),
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
          // credentials: "include",
        }
      );
      const accessToken = res?.data?.accessToken;
      // console.log(
      //   "data from login from",
      //   res.data.email,
      //   res.data.accessToken,
      //   res.data
      // );

      if (res.data.email) {
        setAuth({
          email: res.data.email,
          accessToken: accessToken,
          isPaid: res.data.isPaid,
        });
        setLoginStat(true);
        dispatch(resetMarketData());
        if (res?.data?.surveyComplete) setUserQuestions(false);
        setPersist(true);
        navigate.push("/app/home");
        setButtonLoading(false);
        localService.setLoginStatus(true);
      }

      // console.log(res.data);

      //   navigate("/app/home", { replace: true });
    } catch (e) {
      // const fakeToken = "fakeToken";
      // JwtAuthService.login(values)
      //   .then((resp) => {
      //     authenticated(fakeToken);
      //   })
      //   .then((e) => {
      //     showAuthMessage(e);
      //   });
      loginFail();
      setButtonLoading(false);

      console.log(e);
    }
  };

  const onFacebookLogin = () => {
    showLoading();
  };

  // useEffect(() => {
  //   // if (token !== null && allowRedirect) {
  //   //   history.push(redirect);
  //   // }
  //   // if (showMessage) {
  //   //   setTimeout(() => {
  //   //     hideAuthMessage();
  //   //   }, 3000);
  //   // }
  // });

  const renderOtherSignIn = (
    <div>
      <Divider>
        <span className="text-muted font-size-base font-weight-normal">
          or connect with
        </span>
      </Divider>
      <div className="google_btn_container">
        {/* <GoogleLogin
          clientId={client_id}
          // render={(renderProps) => (
          //   <i
          //     className="fa fa-google-plus"
          //     onClick={renderProps.onClick}
          //     disabled={renderProps.disabled}
          //   ></i>
          // )}
          buttonText="CONTINUE WITH GOOGLE"
          onSuccess={responseGoogle}
          onFailure={responseGoogle}
          cookiePolicy={"single_host_origin"}
          style={{ width: "100%" }}
        /> */}
        <GoogleLogin
          onSuccess={(credentialResponse) => {
            // console.log(credentialResponse);
            responseGoogle(credentialResponse);
          }}
          onError={() => {
            console.log("Login Failed");
          }}
        />
      </div>
    </div>
  );

  return (
    <>
      <motion.div
        initial={{ opacity: 0, marginBottom: 0 }}
        animate={{
          opacity: showMessage ? 1 : 0,
          marginBottom: showMessage ? 20 : 0,
        }}
      >
        {/* <Alert type="error" showIcon message={message1}></Alert> */}
      </motion.div>
      <Form layout="vertical" name="login-form" onFinish={onLogin}>
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please input your email",
            },
            {
              type: "email",
              message: "Please enter a valid email",
            },
          ]}
        >
          <Input
            value={email}
            onChange={(e) => handleEmailInput(e.target.value)}
            prefix={<MailOutlined className="text-primary" />}
          />
        </Form.Item>
        {verifyErrorMessage ? (
          <p className="loginSectionErrorMsg">{verifyErrorMessage}</p>
        ) : null}
        {isEmailVerified ? (
          <>
            <Form.Item
              name="password"
              label={
                <div
                  className={`${
                    showForgetPassword
                      ? "d-flex justify-content-between w-100 align-items-center"
                      : ""
                  }`}
                >
                  <span>Password</span>
                  {showForgetPassword && (
                    <span
                      onClick={() => onForgetPasswordClick}
                      className="cursor-pointer font-size-sm font-weight-normal text-muted"
                    >
                      Forget Password?
                    </span>
                  )}
                </div>
              }
              rules={[
                {
                  required: true,
                  message: "Please input your password",
                },
              ]}
            >
              <Input.Password
                onChange={(e) => setPassword(e.target.value)}
                prefix={<LockOutlined className="text-primary" />}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                block
                loading={buttonLoading}
              >
                Sign In
              </Button>
            </Form.Item>
          </>
        ) : (
          <Form.Item>
            <Button
              type="primary"
              htmlType="button"
              block
              onClick={verifyEmail}
              loading={verifyButtonLoading}
            >
              Continue
            </Button>
          </Form.Item>
        )}
        {/* {otherSignIn ? renderOtherSignIn : null} */}
        {extra}
      </Form>
      {renderOtherSignIn}
    </>
  );
};

LoginForm.propTypes = {
  otherSignIn: PropTypes.bool,
  showForgetPassword: PropTypes.bool,
  extra: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

LoginForm.defaultProps = {
  otherSignIn: true,
  showForgetPassword: false,
};

const mapStateToProps = ({ auth }) => {
  const { loading, message1, showMessage, token, redirect } = auth;
  return { loading, message, showMessage, token, redirect };
};

const mapDispatchToProps = {
  showAuthMessage,
  showLoading,
  hideAuthMessage,
  authenticated,
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
