const initState = Number;

const GetWatchListRoi = (state = initState, action) => {
  switch (action.type) {
    case "WATCHLISTROI": {
      return action.payload;
    }
    default:
      return state;
  }
};

export default GetWatchListRoi;
