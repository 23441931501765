import React, { lazy, Suspense, useEffect } from "react";
import { Route } from "react-router-dom";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import Loading from "components/shared-components/Loading";
import { APP_PREFIX_PATH } from "configs/AppConfig";
import RequireAuth from "../../components/RequireAuth";
import PersistLogin from "../../components/PersistLogin";
import appViewsCSS from "./app_views.module.scss";
import useAuth from "hooks/useAuth";
import { updateUtilsData } from "redux/actions/UtilsData";
import { BASE_URL } from "../../constants";
import { localService } from "./../../services/LocalStorageService";
import PrivacyPolicy from "./compliance/PrivacyPolicy";

const Listing = lazy(() => import("./listing"));
const TopCitiesHomeList = lazy(() => import(`./markets/market-view`));
const Home = lazy(() => import(`./home`));
const Explore = lazy(() => import(`./explore`));
const BillingHome = lazy(() => import(`./billing/BillingHome`));
const AirbnbAudit = lazy(() => import(`./airbnb-audit`));

export const AppViews = () => {
  const state = useSelector((state) => state);
  const dispatch = useDispatch();
  const { auth, loginStat, persist } = useAuth();
  const userVisitStartTime = localService.getUserVisitTime();

  // useEffect(() => {
  //   console.log("auth", auth, loginStat);
  //   if (!loginStat) {
  //     if (!userVisitStartTime) {
  //       localService.setUserVisitTime(new Date().toISOString());
  //       setTimeout(() => {
  //         dispatch(updateUtilsData(true));
  //       }, 20000);
  //     } else {
  //       const currentTime = new Date().toISOString();
  //       const timeDiff =
  //         (new Date(currentTime) - new Date(userVisitStartTime)) / 1000;
  //       if (timeDiff > 20) {
  //         dispatch(updateUtilsData(true));
  //       } else {
  //         setTimeout(() => {
  //           dispatch(updateUtilsData(true));
  //         }, 20000);
  //       }
  //     }
  //   } else {
  //     localService.clearUserVisitTime();
  //   }
  // }, [auth, loginStat]);

  return (
    <Suspense fallback={<Loading cover="content" />}>
      <PersistLogin>
        <Route
          path={`${APP_PREFIX_PATH}/privacy-policy`}
          render={() => <PrivacyPolicy appViewsCSS={appViewsCSS} />}
        />
        <RequireAuth
          path={`${APP_PREFIX_PATH}`}
          // component={lazy(() => import(`../../components/RequireAuth`))}
        >
          <Route
            path={`${APP_PREFIX_PATH}/home`}
            render={() => <Home appViewsCSS={appViewsCSS} />}
          />
          <Route
            path={`${APP_PREFIX_PATH}/watch-list`}
            component={lazy(() => import(`./watch-list`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/pro-picks`}
            component={lazy(() => import(`./pro-picks`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/tracker`}
            component={lazy(() => import(`./tracker`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/saved`}
            component={lazy(() => import(`./saved`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/explore`}
            render={() => <Explore appViewsCSS={appViewsCSS} />}
          />
          <Route
            path={`${APP_PREFIX_PATH}/listing/:id`}
            render={() => <Listing appViewsCSS={appViewsCSS} />}
            appViewsCSS={appViewsCSS}
          />
          <Route
            path={`${APP_PREFIX_PATH}/rankings`}
            component={lazy(() => import(`./rankings`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/markets`}
            component={lazy(() => import(`./markets`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/market/view/:id`}
            render={() => <TopCitiesHomeList appViewsCSS={appViewsCSS} />}
          />
          <Route
            path={`${APP_PREFIX_PATH}/rentalizer`}
            component={lazy(() => import(`./rentalizer`))}
          />
          <Route
            path={`${APP_PREFIX_PATH}/billing`}
            render={() => <BillingHome appViewsCSS={appViewsCSS} />}
          />
          <Route
            path={`${APP_PREFIX_PATH}/audit`}
            render={() => <AirbnbAudit appViewsCSS={appViewsCSS} />}
          />
        </RequireAuth>
      </PersistLogin>
    </Suspense>
  );
};

export default React.memo(AppViews);
