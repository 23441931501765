import React from "react";
import { Menu, Dropdown, Avatar } from "antd";
import { connect } from "react-redux";
import {
  EditOutlined,
  SettingOutlined,
  ShopOutlined,
  QuestionCircleOutlined,
  LogoutOutlined,
  UserOutlined,
} from "@ant-design/icons";
import Icon from "components/util-components/Icon";
import { signOut } from "redux/actions/Auth";
import { GoogleLogout } from "react-google-login";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { gapi } from "gapi-script";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "../../constants";
import useAuth from "hooks/useAuth";
import { localService } from "./../../services/LocalStorageService";
import { resetMarketData } from "./../../redux/actions/MarketData";

const menuItem = [
  {
    title: "Edit Profile",
    icon: EditOutlined,
    // path: "/",
  },

  {
    title: "Account Setting",
    icon: SettingOutlined,
    // path: "/",
  },
  {
    title: "Help Center",
    icon: QuestionCircleOutlined,
    // path: "/",
  },
];

export const NavProfile = ({ signOut }) => {
  const dispatch = useDispatch();
  const { auth, setAuth, setLoginStat } = useAuth();
  const navigate = useHistory();

  const signOutWithCookie = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/auth/logout`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
        // withCredentials: true,
      );
      if (res) {
        const auth2 = gapi?.auth2?.getAuthInstance();
        if (auth2) {
          auth2.disconnect();
        }
        dispatch(resetMarketData());
        document.location = "/app/home";
        localService.setLoginStatus(false);
        setLoginStat(false);
        // setAuth({});
        // navigate.replace("/app");
      }
    } catch (e) {
      console.log(e);
    }
  };

  // const profileImg = "/img/avatars/thumb-1.jpg";
  // const profileImg =
  //   "https://www.pngall.com/wp-content/uploads/12/Avatar-Profile-PNG-Images.png";
  const profileMenu = (
    <div className="nav-profile nav-dropdown">
      <div className="nav-profile-header">
        <div className="d-flex">
          <Avatar
            size={45}
            style={{ backgroundColor: "#3e82f7" }}
            icon={<UserOutlined />}
          />
          <div className="pl-3">
            <h4 className="mb-0"></h4>
            <span className="text-muted"></span>
          </div>
        </div>
      </div>
      <div className="nav-profile-body">
        <Menu>
          {/* {menuItem.map((el, i) => {
            return (
              <Menu.Item key={i}>
                <a href={el.path}>
                  <Icon type={el.icon} />
                  <span className="font-weight-normal">{el.title}</span>
                </a>
              </Menu.Item>
            );
          })} */}
          <Menu.Item
            key={menuItem.length + 1}
            onClick={() => {
              navigate.push("/app/billing");
            }}
          >
            <span>
              <ShopOutlined />
              <span className="font-weight-normal">Billing</span>
            </span>
          </Menu.Item>

          <Menu.Item
            key={menuItem.length + 2}
            onClick={() => signOutWithCookie()}
          >
            <span>
              <LogoutOutlined />
              <span className="font-weight-normal">Sign Out</span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>
  );
  return (
    <Dropdown placement="bottomRight" overlay={profileMenu} trigger={["click"]}>
      <Menu className="d-flex align-item-center" mode="horizontal">
        <Menu.Item key="profile">
          <Avatar
            style={{ backgroundColor: "#3e82f7" }}
            icon={<UserOutlined />}
          />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default connect(null, { signOut })(NavProfile);
