const initState = {};

const SelectedHome = (state = initState, action) => {
  switch (action.type) {
    case "SELECTEDHOME": {
      return action.payload;
    }
    case "UPDATE_SELECTED_HOME_DATA": {
      return action.payload;
    }
    default:
      return state;
  }
};

export default SelectedHome;
