import React, { useEffect, useLayoutEffect, useRef } from "react";
import { Provider } from "react-redux";
import store from "./redux/store";
// import store2 from "./store";
import { BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import Views from "./views";
import { Route, Switch } from "react-router-dom";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { THEME_CONFIG } from "./configs/AppConfig";
import PersistLogin from "./components/PersistLogin";
import useAuth from "hooks/useAuth";
import { localService } from "./services/LocalStorageService";
import { updateUtilsData } from "./redux/actions/UtilsData";
import CookieConsent from "react-cookie-consent";
const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  const dispatch = useDispatch();
  const userVisitStartTime = localService.getUserVisitTime();
  const { auth, loginStat, setLoginStat, persist } = useAuth();
  const myTimeout = useRef();

  useLayoutEffect(() => {
    if (!auth.isPaid) {
      setLoginStat(false);
      localService.setLoginStatus(false);
    }
  }, []);

  useEffect(() => {
    console.log("auth", auth, loginStat);
    if (!loginStat) {
      if (!userVisitStartTime) {
        localService.setUserVisitTime(new Date().toISOString());
        myTimeout.current = setTimeout(() => {
          dispatch(updateUtilsData(true));
        }, 20000);
      } else {
        const currentTime = new Date().toISOString();
        const timeDiff =
          (new Date(currentTime) - new Date(userVisitStartTime)) / 1000;
        if (timeDiff > 20) {
          dispatch(updateUtilsData(true));
        } else {
          myTimeout.current = setTimeout(() => {
            dispatch(updateUtilsData(true));
          }, 20000);
        }
      }
    } else {
      localService.clearUserVisitTime();
    }
  }, [auth, loginStat]);

  const openLoginPopup = () => {
    clearTimeout(myTimeout.current);
    dispatch(updateUtilsData(true));
  };

  function withProps(Component, props) {
    return function (matchProps) {
      return <Component {...props} {...matchProps} />;
    };
  }

  return (
    <div className="App">
      <ThemeSwitcherProvider
        themeMap={themes}
        defaultTheme={THEME_CONFIG.currentTheme}
        insertionPoint="styles-insertion-point"
      >
        <Router>
          <Switch>
            {/* <PersistLogin> */}
            <Route path="/">
              <Views openLoginPopup={openLoginPopup} />
            </Route>
            {/* </PersistLogin> */}
          </Switch>
        </Router>
      </ThemeSwitcherProvider>
      <CookieConsent>
        This site uses cookies for a better user experience.
      </CookieConsent>
    </div>
  );
}

export default App;
