const getLoginStatus = () => {
  return JSON.parse(localStorage.getItem("isLogin"));
};

const setLoginStatus = (value) => {
  localStorage.setItem("isLogin", JSON.stringify(value));
};

const getUserVisitTime = () => {
  return localStorage.getItem("userVisitStartTime");
};

const setUserVisitTime = (value) => {
  localStorage.setItem("userVisitStartTime", value);
};

const clearUserVisitTime = () => {
  localStorage.removeItem("userVisitStartTime");
};

const setUserSignupQuestions = (value) => {
  localStorage.setItem("signupQuestions", value);
};

const getUserSignupQuestions = () => {
  localStorage.getItem("signupQuestions");
};

export const localService = {
  getLoginStatus,
  setLoginStatus,
  getUserVisitTime,
  setUserVisitTime,
  clearUserVisitTime,
  setUserSignupQuestions,
  getUserSignupQuestions,
};
