const initState = {
  currentPage: 1,
  currentSortedField: null,
  currentSortedOrder: null,
};

const UpdateCityList = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_SORT_FIELD": {
      return {
        ...state,
        currentSortedField: action.payload,
      };
    }
    case "UPDATE_SORT_ORDER": {
      return {
        ...state,
        currentSortedOrder: action.payload,
      };
    }
    case "UPDATE_PAGINATE_PAGE": {
      return { ...state, currentPage: action.payload };
    }
    default:
      return state;
  }
};

export default UpdateCityList;
