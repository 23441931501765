import { combineReducers } from "redux";
import Auth from "./Auth";
import Theme from "./Theme";
import Coords from "./Coordinates";
import SelectedHome from "./SelectedHome";
import GetAllHomeData from "./AllHomeData";
import AreaOverview from "./AreaOverview";
import WatchListRoi from "./WatchListRoi";
import UserData from "./UserData";
import CityList from "./CityList";
import MarketData from "./MarketData";
import UtilData from "./UtilData";

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  initialCoords: Coords,
  selectedHome: SelectedHome,
  allHomeData: GetAllHomeData,
  areaOverview: AreaOverview,
  watch_list_roi: WatchListRoi,
  userData: UserData,
  cityList: CityList,
  marketData: MarketData,
  utilData: UtilData,
});

export default reducers;
