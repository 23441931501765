const initState = [];

const GetAllHomeData = (state = initState, action) => {
  switch (action.type) {
    case "ALLHOMEDATA": {
      return action.payload;
    }
    default:
      return state;
  }
};

export default GetAllHomeData;
