import { createContext, useState } from "react";
import { localService } from "./../services/LocalStorageService";
const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const getInitLoginState = () => {
    const logStat = localService.getLoginStatus();
    if (!logStat) return false;
    return logStat;
  };
  const [auth, setAuth] = useState({});
  // const [persist, setPersist] = useState(
  //   JSON.parse(localStorage.getItem("persist")) || false
  // );
  const [loginStat, setLoginStat] = useState(getInitLoginState());
  const [persist, setPersist] = useState(true);

  return (
    <AuthContext.Provider
      value={{ auth, setAuth, persist, setPersist, loginStat, setLoginStat }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
