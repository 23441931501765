import Base from "antd/lib/typography/Base";
import React, { Fragment } from "react";
import classes from "./PricePlan.module.scss";
import { CheckCircleOutlined } from "@ant-design/icons";
import { Button } from "antd";

const PricePlanList = [
  {
    title: "Free",
    planAmount: 0,
    info: "Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission.",
    icon: <CheckCircleOutlined />,
    feature: [
      "200 MB of Spaces",
      "Unlimited Bandwidth",
      "5 Add on Domains",
      "Free Microsoft Office 365",
    ],
  },
  {
    title: "Premium",
    planAmount: 20,
    info: "Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission.",
    icon: <CheckCircleOutlined />,
    feature: [
      "500 MB of Spaces",
      "Unlimited Bandwidth",
      "10 Add on Domains",
      "Free Microsoft Office 365",
    ],
    popular: true,
  },
  // {
  //     title: 'Premium',
  //     planAmount: 48,
  //     info: "Space, the final frontier. These are the voyages of the Starship Enterprise. Its five-year mission.",
  //     icon: <CheckCircleOutlined />,
  //     feature: [
  //         'Unlimited of Spaces',
  //         'Unlimited Bandwidth',
  //         '10 Add on Domains',
  //         'Free Microsoft Office 365',
  //     ]
  // }
];

const PricePlan = ({ products, handlePlan }) => {
  return (
    <Fragment>
      <div className={classes.pricePlan_sec}>
        <div className={classes.pricePlan_inner}>
          {/* <div className={`${classes.pricePlan_card}`}>
            <h4 className={classes.title}>Free</h4>
            <div className={classes.amount}>
              <h5>
                $0 <span>/Month</span>
              </h5>
            </div>
            <p>
              Space, the final frontier. These are the voyages of the Starship
              Enterprise. Its five-year mission.
            </p>
            <ul>
              <li>
                <i>
                  <CheckCircleOutlined />
                </i>{" "}
                200 MB of Spaces
              </li>
              <li>
                <i>
                  <CheckCircleOutlined />
                </i>{" "}
                200 MB of Spaces
              </li>
              <li>
                <i>
                  <CheckCircleOutlined />
                </i>{" "}
                200 MB of Spaces
              </li>
            </ul>
            <div className={classes.plan_btn}>
              <Button className="subscribe_btn" type="primary">
                Subscribe
              </Button>
            </div>
          </div> */}

          {products &&
            products
              .filter((data) => {
                return data.active === true;
              })
              .map((item) => (
                <div className={`${classes.pricePlan_card} ${classes.popular}`}>
                  <h4 className={classes.title}>Pro Plan</h4>
                  <div className={classes.amount}>
                    <h5>
                      $29 <span>/month</span>
                    </h5>
                  </div>
                  <p style={{ fontSize: "16px" }}>
                    Find your next short term rental investment with unlimited
                    nationwide analytics.
                  </p>
                  <ul>
                    <li>
                      <i>
                        <CheckCircleOutlined />
                      </i>{" "}
                      <p style={{ fontSize: "16px" }}>
                        Full Unlimited Access to All Markets
                      </p>
                    </li>
                    <li>
                      <i>
                        <CheckCircleOutlined />
                      </i>{" "}
                      <p style={{ fontSize: "16px" }}>
                        Find Rental Arbitrage Opportunities
                      </p>
                    </li>
                    <li>
                      <i>
                        <CheckCircleOutlined />
                      </i>{" "}
                      <p style={{ fontSize: "16px" }}>
                        Find High Cash on Cash Properties
                      </p>
                    </li>
                    {/* <li>
                      <i>
                        <CheckCircleOutlined />
                      </i>{" "}
                      <p style={{ fontSize: "16px" }}>
                        Historical & Future Revenue Data
                      </p>
                    </li> */}
                    <li>
                      <i>
                        <CheckCircleOutlined />
                      </i>{" "}
                      <p style={{ fontSize: "16px" }}>
                        New Listings Directly To Your Email
                      </p>
                    </li>
                  </ul>
                  <div className={classes.plan_btn}>
                    <Button
                      onClick={() => handlePlan(item.default_price)}
                      className="subscribe_btn"
                      type="primary"
                    >
                      Subscribe
                    </Button>
                  </div>
                </div>
              ))}
        </div>
      </div>
    </Fragment>
  );
};

export default PricePlan;
