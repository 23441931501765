const initState = {
  marketCityData: [],
  closeAirbnbData: [],
  marketFilterType: "",
  marketFilterPercentage: "",
  marketFilterData: [],
};

const MarketData = (state = initState, action) => {
  switch (action.type) {
    case "UPDATE_FILTER_TYPE": {
      return {
        ...state,
        marketFilterType: action.payload,
      };
    }
    case "UPDATE_FILTER_PERCENTAGE": {
      return {
        ...state,
        marketFilterPercentage: action.payload,
      };
    }
    case "UPDATE_FILTER_DATA": {
      return { ...state, marketFilterData: action.payload };
    }
    case "UPDATE_CITY_DATA": {
      return { ...state, marketCityData: action.payload };
    }
    case "UPDATE_CLOSE_AIRBNB_DATA": {
      return { ...state, closeAirbnbData: action.payload };
    }
    case "RESET_MARKET_DATA": {
      return {
        marketCityData: [],
        closeAirbnbData: [],
        marketFilterType: "",
        marketFilterPercentage: "",
        marketFilterData: [],
      };
    }
    default:
      return state;
  }
};

export default MarketData;
