import {
  CardElement,
  PaymentElement,
  PaymentRequestButtonElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import React, { useEffect, useState } from "react";
import { Input, Button, Select } from "antd";
import { BASE_URL } from "./../constants";
import { useHistory } from "react-router-dom";
import useAuth from "hooks/useAuth";
import useRefreshToken from "hooks/useRefreshToken";
import { resetMarketData } from "./../redux/actions/MarketData";
import PricePlan from "./layout-components/PricePlan";

const { Option } = Select;

function PaymentForm({ setIsOpenPaymentModal }) {
  const { auth, setAuth } = useAuth();
  const dispatch = useDispatch();
  const navigate = useHistory();
  const refresh = useRefreshToken();
  const [products, setProducts] = useState(null);
  const [selectedPlan, setSelectedPlan] = useState();
  const [name, setName] = useState("");
  const [email, setEmail] = useState(auth?.email);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentStat, setPaymentStat] = useState(null);

  useEffect(() => {
    (async () => {
      await getPaymentProduct();
    })();
  }, []);

  const handlePlan = (value) => {
    console.log(`selected ${value}`);
    setSelectedPlan(value);
  };

  const getPaymentProduct = async () => {
    try {
      const res = await axios.get(
        `${BASE_URL}/payment/all_product`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
        // withCredentials: true,
      );
      if (!res?.data) {
        throw new Error("Something went wrong");
      }
      console.log("res@>>>", res.data);
      setProducts(res.data);
    } catch (e) {
      console.log(e);
    }
  };

  const createSubscription = async () => {
    try {
      setPaymentStat("pending");
      const paymentMethod = await stripe.createPaymentMethod({
        card: elements.getElement("card"),
        type: "card",
      });
      const response = await fetch(`${BASE_URL}/payment/subscribe`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          name,
          email,
          paymentMethod: paymentMethod.paymentMethod.id,
          planType: selectedPlan,
        }),
      });
      if (!response.ok) {
        setPaymentStat("failed");
        return;
      }

      const data = await response.json();
      const confirm = await stripe.confirmCardPayment(data.clientSecret);

      if (confirm.error) {
        setPaymentStat("failed");
        return;
      }
      const res = await axios.get(
        `${BASE_URL}/auth/afterPayment?email=${auth.email}`,
        {
          headers: { "Content-Type": "application/json" },
          withCredentials: true,
        }
      );
      console.log("after payment response", res);
      if (!res.data) {
        return;
      }
      dispatch(resetMarketData());
      setAuth({
        accessToken: res.data.accessToken,
        email: res.data.email,
        isPaid: res.data.isPaid,
      });
      setPaymentStat("success");
      window.rewardful("convert", { email: res.data.email });

      setTimeout(() => {
        setIsOpenPaymentModal(false);
      }, 3000);

      // const refreshTokenData = await refresh();
      // console.log("refreshTokenData>>>>>>>>>>>", refreshTokenData);
      // if (refreshTokenData) {
      //   console.log("inside refreshTokenData");
      //   setAuth({
      //     email: "abc@abc.com",
      //     accessToken: refreshTokenData,
      //     isPaid: true,
      //   });
      //   // navigate.push("/app/home");
      // }
    } catch (err) {
      console.error(err);
      setPaymentStat("failed");
    }
  };

  return (
    <div>
      {/* <div className="input_wrapper">
        <span>Products:</span>
        <Select defaultValue="" onChange={handlePlan}>
          <Option value="">Select Plan</Option>
          {products &&
            
        </Select>
      </div> */}
      {!selectedPlan ? (
        <>
          {products ? (
            <PricePlan products={products} handlePlan={handlePlan} />
          ) : null}
        </>
      ) : null}
      {selectedPlan ? (
        <>
          <div className="input_wrapper">
            <span>Name:</span>
            <Input
              value={name}
              placeholder="Full Name"
              onChange={(e) => setName(e.target.value)}
            />
          </div>
          <div className="input_wrapper">
            <span>Email:</span>
            <Input
              value={email}
              placeholder="Enter email"
              disabled
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="cardelement_wrapper">
            <CardElement />
          </div>
          {!paymentStat && (
            <div className="btn_wrapper">
              <Button
                className="subscribe_btn"
                type="primary"
                onClick={createSubscription}
              >
                Subscribe
              </Button>
            </div>
          )}
          {paymentStat === "pending" && (
            <div class="loader-2 center">
              <span></span>
            </div>
          )}
          {paymentStat === "success" || paymentStat === "failed" ? (
            <div className="msgDiv">
              {paymentStat === "success" ? (
                <span className="success_msg">Payment Successful</span>
              ) : (
                <span className="error_msg">Payment Failed</span>
              )}
            </div>
          ) : null}
        </>
      ) : null}
    </div>
  );
}

export default PaymentForm;
