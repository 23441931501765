import React, { useState } from "react";
import { Redirect, Route } from "react-router-dom";
import useAuth from "../hooks/useAuth";
import { Modal, Row, Col, Radio, Input, Button } from "antd";
import axios from "axios";
import { useHistory } from "react-router-dom";
import { BASE_URL } from "./../constants";
import { useSelector, useDispatch } from "react-redux";
import { localService } from "./../services/LocalStorageService";
import { resetMarketData } from "./../redux/actions/MarketData";
import LoginForm from "views/auth-views/components/LoginForm";
import RegisterForm from "views/auth-views/components/RegisterForm";
import { ConsoleSqlOutlined } from "@ant-design/icons";

const backgroundURL = "/img/others/img-17.jpg";
const backgroundStyle = {
  backgroundImage: `url(${backgroundURL})`,
  backgroundRepeat: "no-repeat",
  backgroundSize: "cover",
};

export const RequireAuth = (props) => {
  const { auth, setAuth, persist, setPersist, setLoginStat } = useAuth();
  const dispatch = useDispatch();
  const theme = useSelector((state) => state.theme.currentTheme);
  const utilData = useSelector((state) => state.utilData);
  const [visible, setVisible] = useState(false);
  const [showSignupScreen, setShowSignupScreen] = useState(false);
  const [isSignUpSuccessfull, setIsSignupSuccessfull] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [email, setEmail] = useState("");
  const [userQuestions, setUserQuestions] = useState(true);
  const [userQuestionContent, setUserQuestionContent] = useState({
    connect_with_agent: "",
    get_propety_emails: "",
    finance_prequalify: "",
  });
  const [errorText, setErrorText] = useState("");
  const navigate = useHistory();
  //console.log("auth@>>>>>", auth);
  // const lm = LinkMink("pub_live_z7iQmFxTZndgRB9G5YOT");

  //   console.log(auth);
  //   return auth?.email ? (
  //     <Component {...props} />
  //   ) : (
  //     <Redirect
  //       to={{
  //         pathname: `/auth/login`,
  //         state: { from: location },
  //       }}
  //     />
  //   );
  //   return auth?.email ? children : <Redirect to={`/auth/login`} />;
  if (!auth.email) {
    setTimeout(() => {
      setVisible(true);
    }, 1000);
  }

  const handleOpenSignUpScreen = () => {
    setShowSignupScreen(true);
  };

  const handleOpenSignInScreen = () => {
    setShowSignupScreen(false);
    setIsSignupSuccessfull(false);
  };

  const checkQuestions = () => {
    return localService.getUserSignupQuestions();
  };

  const insertQuestions = () => {
    localService.setUserSignupQuestions(true);
  };

  const responseGoogle = async (response) => {
    console.log(response);
    let tokenId = response.credential;
    const res = await axios.post(
      `${BASE_URL}/googleAuth/user-auth`,
      JSON.stringify({ token: tokenId }),
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        // credentials: "include",
      }
    );

    console.log("res@@>>>", res);

    const accessToken = res?.data?.accessToken;
    // console.log(
    //   "data from login from",
    //   res.data.email,
    //   res.data.accessToken,
    //   res.data
    // );

    if (res.data.email) {
      setAuth({
        email: res.data.email,
        accessToken: accessToken,
        isPaid: res.data.isPaid,
      });
      setUserQuestions(!res?.data?.surveyComplete);
      dispatch(resetMarketData());
      setLoginStat(true);
      setPersist(true);
      navigate.push("/app/home");
      setButtonLoading(false);
      localService.setLoginStatus(true);
    }

    console.log(res.data);
  };

  const radioStyle = {
    // display: "block",
    height: "30px",
    lineHeight: "30px",
  };

  const validateQuestions = async () => {
    const options = {
      method: "POST",
      url: `${BASE_URL}/auth/user-survey`,
      headers: {
        "Content-Type": "application/json",
      },
      data: {
        email: auth.email,
        connect_with_agent: userQuestionContent.connect_with_agent,
        get_propety_emails: userQuestionContent.get_propety_emails,
        finance_prequalify: userQuestionContent.finance_prequalify,
      },
    };
    if (
      userQuestionContent.connect_with_agent !== "" &&
      userQuestionContent.get_propety_emails !== "" &&
      userQuestionContent.finance_prequalify !== ""
    ) {
      console.log(auth.email, userQuestionContent);
      const res = await axios(options).catch((err) => console.log(err));
      console.log(res.status);
      if (res.status === 200) setUserQuestions(false);
    } else {
      setErrorText("All fields required.");
    }
  };

  return (
    <>
      {auth?.email ? (
        <>
          {props.children}
          {userQuestions && (
            <div>
              <Modal
                title="Welcome to Airlytics"
                visible={visible}
                closable={false}
                footer={null}
                className="signInModal"
              >
                <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
                  <Row justify="center" className="align-items-stretch h-100">
                    <Col xs={20} sm={20} md={24} lg={24} className="login_form">
                      <div className="container d-flex flex-column justify-content-center h-100">
                        <>
                          <p>
                            The following questions are part of a survey to help
                            us serve our users better. Nothing will be required
                            on your end.
                          </p>
                        </>
                        <Row justify="center" className="mt-4">
                          <h4>
                            I would like to connect with a local short term
                            rental expert to help find my next short term
                            rental.
                          </h4>
                          <Radio.Group
                            onChange={(e) => {
                              setUserQuestionContent((prev) => ({
                                ...prev,
                                connect_with_agent: e.target.value,
                              }));
                            }}
                            // value={this.state.value}
                          >
                            <Radio style={radioStyle} value="Yes">
                              Yes
                            </Radio>
                            <Radio style={radioStyle} value="No">
                              No
                            </Radio>
                          </Radio.Group>
                        </Row>
                        <Row justify="center" className="mt-4">
                          <h4>
                            I would like to get new high cash flowing properties
                            directly to my email.
                          </h4>
                          <Radio.Group
                            onChange={(e) => {
                              setUserQuestionContent((prev) => ({
                                ...prev,
                                get_propety_emails: e.target.value,
                              }));
                            }}
                            // value={this.state.value}
                          >
                            <Radio style={radioStyle} value="Yes">
                              Yes
                            </Radio>
                            <Radio style={radioStyle} value="No">
                              No
                            </Radio>
                          </Radio.Group>
                        </Row>

                        <Row justify="center" className="mt-4">
                          <h4>
                            I would like to pre-qualify for short term rental
                            financing so I know which properties I can afford.
                          </h4>
                          <Radio.Group
                            onChange={(e) => {
                              setUserQuestionContent((prev) => ({
                                ...prev,
                                finance_prequalify: e.target.value,
                              }));
                            }}
                            // value={this.state.value}
                          >
                            <Radio style={radioStyle} value="Yes">
                              Yes
                            </Radio>
                            <Radio style={radioStyle} value="No">
                              No
                            </Radio>
                          </Radio.Group>
                        </Row>
                        {errorText !== "" && (
                          <div className="text-center mt-4">
                            <p className="text-danger">{errorText}</p>
                          </div>
                        )}
                        <Button
                          onClick={validateQuestions}
                          className="mt-4"
                          type="primary"
                        >
                          Submit
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          )}
        </>
      ) : (
        <>
          {/* <Redirect
            to={{
              pathname: `/auth/login`,
              state: { from: props.location },
            }}
          /> */}
          {props.children}
          {utilData.isShowLoginPopup && (
            <div>
              <Modal
                title="Welcome to Airlytics"
                visible={visible}
                closable={false}
                footer={null}
                className="signInModal"
              >
                <div className={`h-100 ${theme === "light" ? "bg-white" : ""}`}>
                  <Row justify="center" className="align-items-stretch h-100">
                    <Col xs={20} sm={20} md={24} lg={24} className="login_form">
                      <div className="container d-flex flex-column justify-content-center h-100">
                        <Row justify="center">
                          <Col
                            xs={24}
                            sm={24}
                            md={20}
                            lg={12}
                            xl={8}
                            className="login_form"
                          >
                            {!showSignupScreen ? (
                              <>
                                <h4>Sign In or Sign Up </h4>
                                {/* <p>
                                  We'll use your email address to look up your
                                  account or create a new one for you.
                                </p> */}
                                <p>Create a free account to unlock all data.</p>
                              </>
                            ) : (
                              <>
                                <h4>Sign In or Sign Up </h4>
                                {!isSignUpSuccessfull ? (
                                  <p>
                                    We'll use your email address to look up your
                                    account or create a new one for you. Already
                                    have an account?{" "}
                                    <a onClick={handleOpenSignInScreen}>
                                      Sign In
                                    </a>
                                  </p>
                                ) : null}
                              </>
                            )}
                            <div className="mt-4">
                              {showSignupScreen ? (
                                <>
                                  <RegisterForm
                                    {...props}
                                    isSignUpSuccessfull={isSignUpSuccessfull}
                                    setIsSignupSuccessfull={
                                      setIsSignupSuccessfull
                                    }
                                    responseGoogle={responseGoogle}
                                    signInemail={email}
                                    handleOpenSignInScreen={
                                      handleOpenSignInScreen
                                    }
                                  />
                                </>
                              ) : (
                                <LoginForm
                                  {...props}
                                  email={email}
                                  setEmail={setEmail}
                                  setShowSignupScreen={setShowSignupScreen}
                                  buttonLoading={buttonLoading}
                                  setButtonLoading={setButtonLoading}
                                  responseGoogle={responseGoogle}
                                  setUserQuestions={setUserQuestions}
                                />
                              )}
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Modal>
            </div>
          )}
        </>
      )}
    </>
  );
};

{
  /* <Redirect
  to={{
    pathname: `/auth/login`,
    state: { from: props.location },
  }}
/>; */
}

export default RequireAuth;
