const initState = {
  lat: "",
  lng: "",
};

const coords = (state = initState, action) => {
  switch (action.type) {
    case "MAPSCOORDS": {
      return {
        lat: action.payload.lat,
        lng: action.payload.lng,
      };
    }
    case "SOMETHINGELSE":
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    //   case HIDE_AUTH_MESSAGE:
    //     return {
    //       ...state,
    //       message: "",
    //       showMessage: false,
    //     };
    //   case SIGNOUT_SUCCESS: {
    //     return {
    //       ...state,
    //       token: null,
    //       redirect: "/",
    //       loading: false,
    //     };
    //   }
    //   case SIGNUP_SUCCESS: {
    //     return {
    //       ...state,
    //       loading: false,
    //       token: action.token,
    //     };
    //   }
    //   case SHOW_LOADING: {
    //     return {
    //       ...state,
    //       loading: true,
    //     };
    //   }
    //   case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
    //     return {
    //       ...state,
    //       loading: false,
    //       token: action.token,
    //     };
    //   }
    //   case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
    //     return {
    //       ...state,
    //       loading: false,
    //       token: action.token,
    //     };
    //   }
    default:
      return state;
  }
};

export default coords;
