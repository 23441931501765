import React from "react";
import { APP_NAME } from "configs/AppConfig";

export default function Footer() {
  return (
    <footer className="footer">
      <span>
        Copyright &copy; {`${new Date().getFullYear()}`}{" "}
        <span className="font-weight-semibold">{`${APP_NAME}`}</span> All rights
        reserved.
      </span>

      <span className="font-weight-semibold">
        info@airlytics.io |{" "}
        <a href="https://airlytics.io/app/privacy-policy">Privacy Policy</a>
      </span>
    </footer>
  );
}
