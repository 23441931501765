import {
  DashboardOutlined,
  AlertOutlined,
  HomeOutlined,
  ScheduleOutlined,
  AimOutlined,
  SearchOutlined,
  HeartOutlined,
  StockOutlined,
  FireOutlined,
  FolderViewOutlined,
  AuditOutlined,
} from "@ant-design/icons";
import { APP_PREFIX_PATH } from "configs/AppConfig";

const dashBoardNavTree = [
  {
    key: "home",
    path: `${APP_PREFIX_PATH}/home`,
    title: "home",
    icon: HomeOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "markets",
    path: `${APP_PREFIX_PATH}/markets`,
    title: "Markets",
    icon: FireOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: "explore",
    path: `${APP_PREFIX_PATH}/explore`,
    title: "Explore",
    icon: FolderViewOutlined,
    breadcrumb: false,
    submenu: [],
  },
  // {
  //   key: "audit",
  //   path: `${APP_PREFIX_PATH}/audit`,
  //   title: "Auditor",
  //   icon: AuditOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "watch",
  //   path: `${APP_PREFIX_PATH}/watch-list`,
  //   title: "Watch List",
  //   icon: AlertOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "propicks",
  //   path: `${APP_PREFIX_PATH}/pro-picks`,
  //   title: "Pro Picks",
  //   icon: AimOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "tracker",
  //   path: `${APP_PREFIX_PATH}/tracker`,
  //   title: "Tracker",
  //   icon: SearchOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  // {
  //   key: "rankings",
  //   path: `${APP_PREFIX_PATH}/rankings`,
  //   title: "Rankings",
  //   icon: StockOutlined,
  //   breadcrumb: false,
  //   submenu: [],
  // },
  {
    key: "saved",
    path: `${APP_PREFIX_PATH}/saved`,
    title: "Saved",
    icon: HeartOutlined,
    breadcrumb: false,
    submenu: [],
  },

  // {
  //   key: "saved",
  //   path: `${APP_PREFIX_PATH}/saved`,
  //   title: "Rankings",
  //   icon: HeartOutlined,
  //   breadcrumb: false,
  //   submenu: [
  //     {
  //       key: "saved",
  //       path: `${APP_PREFIX_PATH}/saved`,
  //       title: "Tracked",
  //       icon: HeartOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //     {
  //       key: "tracker",
  //       path: `${APP_PREFIX_PATH}/saved2`,
  //       title: "tracker",
  //       icon: HeartOutlined,
  //       breadcrumb: false,
  //       submenu: [],
  //     },
  //   ],
  // },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
