import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import "./index.scss";
// import { createStore } from "redux";
import axios from "axios";
// import jwt_decode from "jwt-decode";
import { AuthProvider } from "./context/AuthProvider";
import store from "./redux/store";
import { Provider } from "react-redux";
import { BASE_URL } from "./constants";
// import useRefreshToken from "./hooks/useRefreshToken";
// import CookieConsent from "react-cookie-consent";
import { GoogleOAuthProvider } from "@react-oauth/google";
// let refresh = false;

// axios.interceptors.response.use(
//   (resp) => resp,
//   async (error) => {
//     if (error.response.status && !refresh) {
//       refresh = true;

//       // const response = await axios.get(
//       //   `${BASE_URL}/auth/refresh`,
//       //   {},
//       //   { withCredentials: true }
//       // );
//       // console.log(response.data);

//       const response = await axios.get(`${BASE_URL}/auth/refresh`, {
//         headers: { "Content-Type": "application/json" },
//         withCredentials: true,
//       });

//       // if (response.status === 200) {
//       axios.defaults.headers.common[
//         "Authorization"
//       ] = `Bearer ${response.data["accessToken"]}`;

//       return axios(error.config);
//       // }
//     }
//     refresh = false;
//     return error;
//   }
// );

ReactDOM.render(
  <Provider store={store}>
    <GoogleOAuthProvider clientId="22314450281-hb9bqbc65tskvgb8b2jef1eumthhnmps.apps.googleusercontent.com">
      <AuthProvider>
        <App />
      </AuthProvider>
    </GoogleOAuthProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
