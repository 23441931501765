import React from "react";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
import useAuth from "../hooks/useAuth";
import { Redirect, Route } from "react-router-dom";
import "../index.scss";

const PersistLogin = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  const { auth, persist } = useAuth();
  //   console.log(auth);

  useEffect(() => {
    let isMounted = true;

    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        isMounted && setIsLoading(false);
      }
    };

    // persist added here AFTER tutorial video
    // Avoids unwanted call to verifyRefreshToken
    !auth?.accessToken && true ? verifyRefreshToken() : setIsLoading(false);

    return () => (isMounted = false);
  }, []);

  // useEffect(() => {
  //   // console.log(`isLoading: ${isLoading}`);
  //   // console.log(`aT: ${JSON.stringify(auth?.accessToken)}`);
  // }, []);

  return (
    // <Route
    //   {...props}
    //   render={() => {
    //     return !persist ? (
    //       props.children
    //     ) : isLoading ? (
    //       <p>Loading...</p>
    //     ) : (
    //       props.children
    //     );
    //   }}
    // />
    <>
      {!persist ? (
        props.children
      ) : isLoading ? (
        <>
          <div className="">
            <div class="loader-2 center">
              <span></span>
            </div>
          </div>
        </>
      ) : (
        props.children
      )}
    </>
  );
};

export default PersistLogin;
